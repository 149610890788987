import React, { forwardRef } from "react";
import { gql } from "@apollo/client";

import {
	TagVariant,
	Tag,
	IconChevron,
	LinkVariant,
	Link,
} from "@jasperlabs/jux-next";

import { InvestingEntityType } from "types";

import { InvestingEntityTypeLabels } from "type-labels";

import { InvestingEntityLabelFragment } from "./InvestingEntityLabel.generated";

type Props = {
	investingEntity: InvestingEntityLabelFragment;
};

const tagVariantFor: Record<InvestingEntityType, TagVariant> = {
	[InvestingEntityType.Trust]: TagVariant.MutedSuccess,
	[InvestingEntityType.Company]: TagVariant.MutedViolet,
	[InvestingEntityType.Individual]: TagVariant.MutedBlue,
	[InvestingEntityType.Partnership]: TagVariant.MutedYellow,
	[InvestingEntityType.JointIndividual]: TagVariant.MutedYellow,
};

function pickInvestingEntityTitle(
	investingEntity: InvestingEntityLabelFragment,
) {
	if (investingEntity?.name) {
		return investingEntity.name;
	}

	return InvestingEntityTypeLabels[investingEntity?.entityTypeEnum];
}

function InvestingEntityLabel({ investingEntity }: Props) {
	const investingEntityTitle = pickInvestingEntityTitle(investingEntity);
	return (
		<div className="flex flex-col items-center p-3">
			<span className="text-overline-sm text-neutral-500">Investing as</span>
			<span className="text-subheading-sm mt-3 mb-1 text-neutral-800">
				{investingEntityTitle}
			</span>
			<Tag
				aria-label="Explanation of Pending Transaction"
				variant={tagVariantFor[investingEntity?.entityTypeEnum]}
			>
				{investingEntity?.entityTypeEnum}
			</Tag>
		</div>
	);
}

const InvestingEntityLabelButton = forwardRef(
	({ investingEntity, ...props }: Props, ref: React.Ref<HTMLButtonElement>) => {
		const investingEntityTitle = pickInvestingEntityTitle(investingEntity);

		// @ts-ignore
		const [abbreviation] = investingEntityTitle;

		return (
			<button
				title={investingEntityTitle}
				type="button"
				className="text-link-nav hover:bg-button-inverted-nav-hover/20 flex h-[40px] items-center gap-2 rounded-md bg-transparent px-2 py-2 lg:h-[48px] focus:outline-none"
				ref={ref}
				{...props}
			>
				<span className="bg-avatar text-avatar h-[26px] w-[26px] items-center justify-center rounded-full lg:h-[32px] lg:w-[32px] hidden xl:flex">
					<span className="text-overline-sm leading-none">{abbreviation}</span>
				</span>
				<span className="text-subheading-sm text-nav hidden max-w-[80px] truncate lg:block xl:max-w-[150px]">
					{investingEntityTitle}
				</span>
				<IconChevron className="mb-1 hidden text-xl lg:block" />
			</button>
		);
	},
);

function InvestingEntityLabelAccordionTitle({
	investingEntity,
	...props
}: Props) {
	const investingEntityTitle = pickInvestingEntityTitle(investingEntity);

	// @ts-ignore
	const [abbreviation] = investingEntityTitle;

	return (
		<Link
			as="div"
			variant={LinkVariant.Black}
			className="flex justify-between"
			aria-label="Investing Entities Control"
			{...props}
		>
			<div className="flex flex-col items-start gap-2">
				<div className="flex gap-3">
					<span className="bg-primary-400 flex h-[26px] w-[26px] items-center justify-center rounded-full text-white">
						<span className="text-overline-sm leading-none">
							{abbreviation}
						</span>
					</span>
					<span className="text-subheading-sm max-w-[150px] justify-self-start truncate text-black">
						{investingEntityTitle}
					</span>
				</div>
				<div>
					<Tag
						aria-label="Explanation of Pending Transaction"
						variant={tagVariantFor[investingEntity?.entityTypeEnum]}
					>
						{investingEntity?.entityTypeEnum}
					</Tag>
				</div>
			</div>
			<IconChevron className="text-xl transition-transform duration-300 ease-in-out" />
		</Link>
	);
}

InvestingEntityLabel.fragment = gql`
	fragment InvestingEntityLabel on InvestingEntity {
		id
		name
		entityTypeEnum
	}
`;

InvestingEntityLabel.Button = InvestingEntityLabelButton;
InvestingEntityLabel.AccordionTitle = InvestingEntityLabelAccordionTitle;

export default InvestingEntityLabel;
