import React from "react";
import { Link, LinkProps } from "@jasperlabs/jux-next";

import { useActiveTenant } from "tenants/TenantProvider";

export function TenantTermsAndConditionsLink({
	children = "Terms & Conditions",
	...props
}: Omit<LinkProps<"a">, "href" | "children"> & { children?: React.ReactNode }) {
	const tenant = useActiveTenant();

	const { termsAndConditionsUrl } = tenant.urls;

	return (
		<Link href={termsAndConditionsUrl ?? ""} target="_blank" {...props}>
			{children}
		</Link>
	);
}
